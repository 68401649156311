html,
body {
  height: 100vh;
  font-size: 16px;
  line-height: 1.5;
  color: $font-color-regular;
  background-color: $info;
}

// === Layout ===
.ts-header {
  padding: 0.75rem 0;
  min-height: 64px;
  background-color: $light;
  // border-bottom: 1px solid $secondary;
}
.ts-header,
.ts-alert,
.ts-main,
.ts-app-popup {
  min-width: 320px;
}
.ts-main {
  background-color: $light;
}
.ts-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
}
.ts-header__signin {
  margin-left: 3rem;
}
.ts-header__create-account {
  margin-right: -50px;
}

// === Navigation ===
.ts-nav__link {
  color: $font-color-regular;

  &:hover {
    color: $font-color-primary;
  }
}
.ts-nav__link-extra {
  padding: 1.5rem 0;
  color: $font-color-primary;
}
.nav-item:last-child .ts-nav__link {
  padding-right: 0;
}

.ts-nav__toggle {
  display: none;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}
.ts-hamburger {
  position: absolute;
  top: 50%;
  left: 0;
  width: 1.2rem;
  height: 0.2rem;
  background-color: $secondary;
  border-radius: 0.1rem;
}
.ts-hamburger:before,
.ts-hamburger:after {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0rem;
  width: 1.2rem;
  height: 0.2rem;
  background-color: $secondary;
  border-radius: 0.1rem;
}
.ts-hamburger:before {
  top: 0.5rem;
}
.ts-hamburger:after {
  top: -0.5rem;
}

// === Modal-dialog. Sidemenu ===
.ts-modal-dialog {
  position: absolute;
  margin: 0;
  max-width: 720px;
  width: 100%;
  height: 100vh;
}

.ts-modal-dialog--left {
  left: 0;
  animation: sidemenuKeyframes--left__open 0.7s ease;
}
.dialog-close .ts-modal-dialog--left {
  animation: sidemenuKeyframes--left__close 0.8s ease;
}
.ts-modal-dialog--right {
  right: 0;
  animation: sidemenuKeyframes--right__open 0.7s ease;
}
.dialog-close .ts-modal-dialog--right {
  animation: sidemenuKeyframes--right__close 0.8s ease;
}
.ts-overlay:before {
  animation: sidemenuKeyframes--opacity__open 0.8s ease;
}
.dialog-close .ts-overlay:before {
  animation: sidemenuKeyframes--opacity__close 0.8s ease;
}
@keyframes sidemenuKeyframes--opacity__open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes sidemenuKeyframes--opacity__close {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sidemenuKeyframes--left__open {
  0% {
    transform: translateX(-720px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sidemenuKeyframes--left__close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-720px);
  }
}
@keyframes sidemenuKeyframes--right__open {
  0% {
    transform: translateX(720px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sidemenuKeyframes--right__close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(720px);
  }
}
.ts-modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  overflow: scroll;
}
.ts-modal-content__auth {
  max-height: 90%;
}
.ts-modal-header {
  padding: 2rem 5rem 0rem 5rem;
  border: none;
  & .close:focus {
    outline: 0;
  }
  & .close:active {
    transform: scale(1.2);
  }
}
.ts-sidemenu,
.ts-inner-layout {
  overflow: scroll;
  background-color: $light;
}
.ts-alert__sidemenu,
.ts-inner-layout__header {
  padding: 0 5rem;
}
.ts-alert__sidemenu-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  pointer-events: auto;
}
.ts-sidemenu,
.ts-inner-layout__body {
  padding: 0 5rem 5rem;
}
.ts-sidemenu__title {
  margin: 0;
  padding: 2.5rem 0;
  font-size: 1.5rem;
  line-height: 1.875rem;
}
.ts-sidemenu__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ts-sidemenu__separate {
  height: 4rem;
}
.ts-sidemenu__item {
  font-weight: bold;
  color: $font-color-primary;
  font-size: 1.5rem;
  line-height: 1.875rem;

  &:hover {
    color: darken($font-color-primary, 15%);
    text-decoration: none;
  }
  &:hover path,
  &:hover rect {
    fill: darken($font-color-primary, 15%);
  }
}
.ts-sidemenu__item-disabled {
  color: $font-color-regular;

  &:hover {
    color: darken($font-color-regular, 15%);
    text-decoration: none;
  }
  &:hover path,
  &:hover rect {
    fill: darken($font-color-regular, 15%);
  }
}
.ts-sidemenu__item-hint {
  font-family: $font-family-text;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

// === Sidemenu. Inner. ===
.ts-inner-layout__icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.ts-inner-layout__title {
  margin: 0;
  margin-left: 2rem;
  padding: 2rem 0;
  font-size: 1.5rem;
  color: $font-color-primary;
}
.ts-recipients__text {
  padding: 0.5rem 4.5rem 1.5rem 0;
  font-family: $font-family-text;
}
.ts-recipients__list {
  list-style: none;
  padding: 0;
}
.ts-recipients__item {
  position: relative;
  padding-right: 5rem;
  cursor: pointer;
}
.ts-recipients__actions {
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
  display: none;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-around;
}
.ts-recipients__item:hover .ts-recipients__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
// .ts-recipients__item:hover :after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   right: 10%;
//   margin-top: -0.75rem;
//   width: 1.5rem;
//   height: 1.5rem;
//   background-image: url("../icons/trash.svg");
//   background-repeat: no-repeat;
// }
.ts-recipients__name {
  color: $font-color-primary;
  max-width: 70%;
  padding-right: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ts-recipients__textarea {
  min-height: 10rem;
}
.ts-recipients__frequency {
  text-align: right;
  white-space: nowrap;
}

.ts-skip__text {
  padding: 1rem 9rem 3rem 0;
  font-family: $font-family-text;
}
.ts-refer-friends {
  padding-right: 6rem;
}

// === Alert ===
.ts-alert {
  padding: 1rem 0;
}
.ts-alert--primary {
  color: $font-color-secondary;
  background-color: $primary;
}
.ts-alert--danger {
  color: $light;
  background-color: $warning;
}
.ts-btn__alert {
  padding-right: 0;
  color: $light;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    color: $font-color-secondary;
  }

  &:focus {
    box-shadow: none;
  }
}
.ts-alert__icon {
  color: $light;
}

.ts-alert-clickable:hover {
  cursor: pointer;
}

.ts-alert__signup {
  width: 300px;
  padding: 0;
}

.ts-btn__signup-banner {
  background-color: $light;
  color: $font-color-primary;

  &:hover {
    color: $font-color-primary;
  }
}

.ts-text__signup-banner {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  color: $font-color-secondary;

  &:hover {
    color: $font-color-secondary;
  }
}

.ts-share-button {
  height: 40px;
  margin-bottom: 10px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    transform: scale(1.05)
  }
}

// === App pop-up ===
.ts-app-popup {
  display: none;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $primary;
  border-bottom: 1px solid $info;
  z-index: 200;

  &:hover,
  &:active {
    transform: scale(1.05)
  }
}
.ts-app-popup__text {
  max-width: 26rem;
  color: $light;
}
.ts-app-popup__btn {
  padding: 0.7rem 0 0.5rem;
  // color: $secondary;
}
.ts-app-popup__logo {
  background-color: $info;
  border-radius: 0.625rem;
}
.ts-app-popup__caption {
  font-size: 0.65rem;
  line-height: 1.6;
  white-space: nowrap;
  color: $light;
}
.ts-app-popup__link {
  &:hover,
  &:active {
    // transform: scale(1.05);
    text-decoration: none;
    color: $dark_secondary;
  }
}
.ts-app-popup__container {
  padding: 0 40px 0 25px;
}
.ts-app-popup__close {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 13px !important;
  color: white;
  opacity: 1 !important;
  z-index: 10
}

// === Onboarding ===
.ts-onboarding__messege-fixed {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: $light;
  z-index: 10;

  & span {
    width: 45%;
  }

  & .ts-btn {
    min-width: 12rem;
  }
}

// === Account ===
.ts-account {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $info;
  // border-bottom: 1px solid $secondary;
}
.ts-account__name,
.ts-avatar-details__name {
  font-size: 2rem;
  line-height: 1.2;
}
.ts-account__nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ts-avatar__img {
  border-radius: 65px;
  cursor: pointer;
}
.ts-avatar-details__img {
  margin: 2rem 1.5rem 2rem 0;
  background-color: $secondary;
  border-radius: 40px;
  cursor: pointer;
}
.ts-account-nav__item {
  // max-width: 15rem;
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    .ts-account-nav__item-icon path {
      fill: $font-color-primary;
    }
    color: $font-color-primary;
  }
}
.ts-account-nav__item--select {
  color: $font-color-primary;
  & .ts-account-nav__item-icon path {
    fill: $font-color-primary;
  }
}

// === PhotosBox ===
.ts-photos-box {
  padding-bottom: 20px !important;
  margin-bottom: 20px;
  background-color: $info;
}

.ts-photos-box__header {
  padding: 3rem 0 2rem;
  // border-top: 1px solid $secondary;
}

.ts-photos-box__date,
.ts-photos-box__counter,
.ts-tooltip__header {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.25rem;
}
.ts-photos-box__counter--color {
  color: $warning;
}
.ts-photos-box__message,
.ts-tooltip__body {
  font-family: $font-family-text;
  position: relative;
}
.ts-photos-box__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.ts-photos-box__image-wrap {
  position: relative;
  width: 100%;
  cursor: pointer;
  &.square {
    aspect-ratio: 1;
  }
  &.wide {
    aspect-ratio: 1.5;
  }
  &.tall {
    aspect-ratio: 1 / 1.5;
  }
}
.ts-photos-box__image-wrap--pointer {
  cursor: default;
}
.ts-photos-box__image,
.ts-photos-box__image-twice,
.ts-photos-box__image-twice--back {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.4rem;
}
.ts-photos-box__image-twice,
.ts-photos-box__image-twice--back {
  width: 100%;
  height: 100%;
  transform: scale(0.95);
}
.ts-photos-box__image-twice {
  // NOTE: used in-line styling because using a % for 'top' wasn't working -- see Photo.js
  // position: relative;
  // right: -2.5%;
  // top: 2.5%;
  z-index: 2;
}
.ts-photos-box__image-twice--back {
  position: absolute;
  top: -2.5%;
  left: -2.5%;
  z-index: 1;
}
.ts-photos-box__image--border {
  border: 0.7rem $light solid;
  border-radius: 0.4rem;
}

.ts-photos-box__image:before,
.ts-photos-box__image-twice:before,
.ts-photos-box__image-twice--back:before {
  content: "";
  display: block;
}
.ts-photos-box__image--ratio1_1 {
  &.wide:before {
    padding-top: 66.66666666667%;
  }
  &.tall:before {
    padding-top: 150%;
  }
  &.square:before {
    padding-top: 100%;
  }
}
.ts-photos-box__image-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ts-next-month-container,
.ts-past-month-container {
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.ts-past-month-container {
  margin-bottom: 150px;
}
.ts-next-month-wrap:hover,
.ts-past-month-wrap:hover {
  cursor: pointer;
  transform: scale(1.1)
}
.ts-next-month-button,
.ts-past-month-button {
  width: 500px;
}
.ts-next-month-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
.ts-past-month-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.ts-year-btn {
  width: 500px;
  margin: 20px auto;
  background-color: $info;
  border-radius: 20px;
  text-align: center;
  position: relative;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}
.ts-year-text {
  font-size: 24px;
}
.ts-year-triangle {
  width: 150px;
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, 0);
  // z-index: -10;
  margin-bottom: 10px;
}
// === Viewer ===
.viewer__wrap {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.8);
}
.btn-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: $light;
  height: 7.5rem;
  margin-top: -3.75rem;
  width: 5rem;
  background: none;
  border: none;
  outline: none;
  z-index: 1;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    color: $disabled;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
.arrow {
  width: 30px;
  height: 50px;
}
.btn-arrow__direction--left {
  left: 0;
}
.btn-arrow__direction--right {
  right: 0;
}

// === New viewer frame ===
.ts-image-frame__wrap {
  position: relative;
}
.ts-image-frame {
  position: relative;
  overflow: hidden;
  box-sizing: content-box;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// === Viewer. Setting of image ===
.ts-setting {
  width: 100%;
  padding: 1rem;
  background-color: $light;
  border-top: 1px solid $info;
}
.ts-setting__twice {
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: $dark;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ts-setting--border {
  border: 1rem solid $light;
}
.ts-setting__twice--border {
  border: 1rem solid $light;
  top: -1.5rem;
  left: -1.5rem;
}
.ts-setting__count-display {
  flex: 1;
}

// === Switch ===
.ts-btn__switch {
  position: relative;
  width: 100%;
  min-width: 9rem;
  min-height: 5.5rem;
  margin: 0;
  padding: 0.5rem 1rem;
  background-color: $info;
  border: 0.5rem solid $info;
  border-radius: 0.2rem;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $light;
    border-radius: 0.2rem;
    transition: left 400ms cubic-bezier(0, 1, 1, 1);
  }
}
.ts-switch {
  display: none;

  &:checked + .ts-btn__switch {
    cursor: default;
    z-index: 1;

    &:after {
      left: 0;
    }
  }
}
.switch--right + .ts-btn__switch {
  margin-left: -0.3rem;
  &:after {
    left: -100%;
  }
}
.switch--left + .ts-btn__switch:after {
  left: 100%;
}
.ts-switch__photos-amount {
  // width: 10.5rem;
  flex: 2;

  & > .ts-btn__switch {
    min-width: 4rem;
    min-height: 100%;
    line-height: 1;
  }
}

// === Cropper and Editor ===
.ts-crop-edit-container {
  padding: 1rem;
  background-color: $light;
}
.ts-crop-settings {
  width: 300px;
}
.ts-crop-setting {
  background-color: $option;
  width: 60px;
  height: 60px;

  &.active {
    background-color: $light;
    border: 2px solid $option;
  }

  &:hover {
    cursor: pointer;
  }
}
.ts-crop-message.safe-zone {
  color: $primary
}
.ts-crop-message.danger-zone {
  color: $warning
}
.ts-crop-message.no-message {
  color: $light
}
.ts-crop-message p.ts-crop-message-title {
  font-family: $font-family-title;
  margin-bottom: 4px;
}
.ts-crop-message p {
  font-size: 14px;
  font-family: $font-family-text;
  margin-bottom: 0;
  line-height: 1;
  text-align: center;
}
.ts-edit-slider-container {
  width: 80%;
}
.ts-edit-slider {
  // -webkit-appearance: none;
  width: 100%;
  cursor: pointer;
  // color: $light;
  // background-color: $light;
  // background: $light;
}
.ts-edit-slider-label {
  font-size: 14px;
}
.ts-edit-image {
  background-position: top center;
  background-size: contain;
}

// === Modal-dialog. SignUp & Login & Submit ===
.ts-modal {
  overflow: visible;
}
.ts-modal__options {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ts-modal--visible {
  display: block;
}
.ts-overlay:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $dark;
  opacity: 0.5;
  z-index: 50;
}
.ts-modal-dialog__options {
  margin-top: 0;
  margin-bottom: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ts-modal-dialog__options .modal-content {
  overflow: scroll;
}
.ts-modal-title {
  text-align: center;
}
.ts-modal-dialog__header {
  // position: relative;
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
  border: none;
}
.ts-modal-dialog__btn-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.ts-modal-dialog__body {
  padding: 0 3rem 2rem;
  text-align: center;
  font-family: $font-family-text;
}
.ts-modal-login__text {
  text-align: center;
}
.ts-modal-login__text {
  font-size: 12px;
}

// === Forms ===
.ts-form__title {
  font-size: 1.5rem;
  line-height: 2rem;
}
.ts-form__text {
  font-family: $font-family-text;
}
.ts-form__subcopy {
  font-family: $font-family-text;
  font-size: 14px;
}
.ts-form-control {
  padding: 0.6rem 1rem;
  color: $font-color-regular;
  font-family: $font-family-text;
  border-color: $info;
  border-radius: 0.2rem;

  &::placeholder {
    color: $font-color-regular;
    opacity: 0.7;
  }

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }

  &:disabled {
    background-color: $light;
  }
}
.ts-form-control__no-border {
  border: none;
}
.ts-form__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ts-form__input-wrap {
  position: relative;
}
.ts-form__input__lable {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.ts-form-check {
  line-height: 2rem;
}
.ts-form-check-label {
  position: relative;
  width: 100%;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.1rem solid $secondary;
    border-radius: 0.75rem;
  }
}
.ts-form-check-input {
  display: none;

  &:checked + .ts-form-check-label {
    color: $font-color-primary;

    &:before {
      background: url("../icons/check.svg") $primary no-repeat 50% 50%;
      border-color: $primary;
    }
  }
}
.ts-payment__form,
.ts-sidemenu__form {
  width: 50%;
}
.ts-form-control--border {
  border: 1px solid $warning;
}

.ts-render-field {
  position: relative;
  width: 100%;
}
.ts-render-field__message {
  position: absolute;
  bottom: -1.2rem;
  left: 0;
  font-size: 0.8rem;
  font-family: $font-family-text;
  color: $warning;
}
.ts-refer-friends__form {
  border-bottom: 1px solid $info;
}
.ts-refer-friends__input {
  width: 60%;
  font-size: 1.25rem;
  color: $font-color-regular;
  background-color: $light;
  border-color: $info;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }
}

// === Buttons ===
.ts-btn {
  font-family: $font-family-base;
  padding: 0.5rem 1.5rem;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}
.ts-btn-link {
  font-family: $font-family-base;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:active {
    transform: scale(1.05);
  }
}
.ts-btn-link__nav {
  padding: 0.5rem 0.75rem;
  color: $secondary;
  border: none;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
.ts-btn-link__account-details {
  font-family: $font-family-text;
}
.ts-btn-link__sidemenu {
  padding: 0;
}
.ts-btn-link__left {
  text-align: left;
}
.ts-btn-link__inline {
  // color: $secondary;
  // font-weight: bolder;
  font-family: $font-family-base;

  &:hover {
    cursor: pointer;
    color: $primary;
    text-decoration: none;
  }
}
.ts-btn-link-disabled {
  color: $font-color-regular !important;
  // background-color: $disabled;
  opacity: 1 !important;

  &:hover {
    color: $font-color-regular;
    cursor: auto;
  }
}
.ts-btn__sidemenu,
.ts-btn__modal-login,
.ts-btn__modal-submit,
.ts-btn__google-photos {
  width: 16.75rem;
}
.ts-btn__widespread {
  width: 100%;
}
.ts-btn-outline-primary:hover {
  color: darken($font-color-primary, 15%);
  border-color: darken($font-color-primary, 15%);
  background-color: $light;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}
.ts-btn-disabled {
  color: $font-color-secondary;
  background-color: $disabled;
  opacity: 1 !important;

  &:hover {
    color: $font-color-secondary;
  }
}
.ts-btn-upload,
.ts-btn-submit,
.ts-btn-disabled {
  min-width: 10.5rem;
}
.ts-btn-upload__wrap {
  width: 100%;
}

.ts-social__btn {
  width: 10rem;
  height: 5.5rem;
  padding: 1.25rem 0;
  border-style: solid;
  border-width: 0.125rem;
  border-radius: 0.2rem;

  & .ts-social__btn__icon {
    color: $light;
    transform: scale(1.8);
  }
  &:active {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $light;
  }
}
.ts-social__btn--mail {
  background-color: $mail;
  border-color: $mail;
  &:hover .ts-social__btn__icon {
    color: $mail;
  }
}
.ts-social__btn--facebook {
  background-color: $facebook;
  border-color: $facebook;
  &:hover .ts-social__btn__icon {
    color: $facebook;
  }
}
.ts-social__btn--twitter {
  background-color: $twitter;
  border-color: $twitter;
  &:hover .ts-social__btn__icon {
    color: $twitter;
  }
}
.ts-btn.ts-btn__edit {
  width: 30%;
  padding: 0.5rem 0;
  text-align: center;
}

// === Preloader ===
.ts-preloader-wrap {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 201;
}
.ts-bounce {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;
  background: $secondary;
  border-radius: 50%;
  animation: bouncingKeyframes 0.7s infinite ease-in-out alternate;
}
.ts-bounce_2 {
  animation-delay: 0.2s;
}
.ts-bounce_3 {
  animation-delay: 0.4s;
}
.ts-btn-error {
  background-color: $warning;
  border-color: $warning;

  &:hover,
  &:focus {
    background-color: darken($warning, 7%) !important;
    border-color: darken($warning, 7%) !important;
  }
  &:active {
    background-color: darken($warning, 10%) !important;
    border-color: darken($warning, 10%) !important;
  }
}
@keyframes bouncingKeyframes {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0.1;
    transform: translateY(2rem);
  }
}

// === Custom helper ===
.ts-tooltip__wrapper {
  position: relative;
}

.ts-tooltip {
  position: absolute;
  padding: 2.5rem;
  max-width: 34rem;
  background-color: $light;
  border-radius: 0.375rem;
  z-index: 50;
  border: $info solid 1px;
  box-shadow: 0.2rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.8);
  width: 500px;
}

.ts-tooltip__header-preferences {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -3.3rem;
    left: -1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $light;
    transform: rotate(45deg);
    border-top: $info solid 1px;
    border-left: $info solid 1px;
  }
}

.ts-tooltip__header-moments {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -3.3rem;
    right: -1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $light;
    transform: rotate(45deg);
    border-top: $info solid 1px;
    border-left: $info solid 1px;
  }
}

.ts-tooltip__header-account {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -3.3rem;
    left: -1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $light;
    transform: rotate(45deg);
    border-top: $info solid 1px;
    border-left: $info solid 1px;
  }
}

.ts-tooltip__btn-close {
  position: absolute;
  top: 0;
  right: 0;
}

.ts-tooltip__progress {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.ts-tooltip__progress-circles {
  width: 6rem;
  display: flex;
  justify-content: space-around;
}

.ts-tooltip__progress-circle {
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 100%;
  background-color: $info;
}

.ts-tooltip__progress-circle.active {
  background-color: $primary;
}

.ts-tooltip__buttons {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  display: flex;
}

.ts-tooltip__buttons-preferences {
  justify-content: flex-end;
}

.ts-tooltip__buttons-account,
.ts-tooltip__buttons-moments {
  justify-content: space-between;
}

.ts-tooltip__button-left {
  position: relative;
  left: -0.75rem;
  bottom: -0.375rem;
}

.ts-tooltip__button-right {
  position: relative;
  right: -0.75rem;
  bottom: -0.375rem;
}

.modal-buttons {
  padding-bottom: 5%;
}

.chooser-button {
  width: 80%;
  // height: '50px';
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 20px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  // border-color: $secondary;
  // color: $secondary;

  // &:hover {
  //   background-color: $primary;
  //   border-color: white;
  //   color: white;
  //   transform: scale(1.05);
  // }

  // &:active {
  //   transform: scale(1.02);
  // }
}

.ts-viewer__button-close {
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: -1rem;
  right: -1rem;
  border-radius: 100%;
  color: white;
  background-color: $primary;
  border-style: none;
  // opacity: 0.7;
  font-size: 1.8rem;
  z-index: 10;
  padding: 0;
  text-align: center;
}

// .ts-viewer__moment-count {
//   float: left;
//   background-color: white;
//   color: $font-color-regular;
//   position: absolute;
//   top: 92%;
//   left: 42%;
//   width: 80px;
//   text-align: center;
//   font-size: 18px;
// }

.ts-google-photos {
  height: 90%;
}

.ts-google-account {
  font-family: $font-family-text;
}

.ts-google-switch-account {
  padding-top: 0;
  padding-bottom: 0;
}

.ts-google-photos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0px;
  width: 95%;
  margin: 0 auto;
  flex: 1;
  overflow-y: scroll;
}

.ts-google-photos-grid div {
  position: relative;
}

// .ts-google-photos-grid div {
//   width: 100%;
//   paddingTop: 100%;
//   border: 1px solid white;
//   cursor: pointer;
// }

.ts-google-photo {
  width: 100%;
  padding-top: 100%;
  border: 1px solid white;
  cursor: pointer;
}

.ts-google-photos-signin,
.ts-google-photos-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 95%;
  margin: 0 auto;
  flex: 1;
}

// .ts-btn__google-photos {
//   // margin-top: 1rem;
// }

#ts-google-photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  // opacity: 0.65;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ts-google-photo-icon {
  width: 40%;
  border: none;
  opacity: 1;
}

#ts-google-photos-count {
  margin: 3px;
  font-size: 18px;
}

#ts-moment-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // background-color: $secondary;
  // opacity: 0.75;
  background-color: rgba(140, 133, 123, 1); // $secondary
  // background-color: rgba(102, 102, 102, 1); // darker gray
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 0.4rem;
}

#ts-moment-overlay-failed {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(140, 133, 123, 0.75); // $secondary
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 0.4rem;
}

#ts-moment-overlay-limit {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(245, 139, 125, 0.75); // $warning
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 0.4rem;
}

#ts-moment-overlay-logo {
  width: 30%;
}

#ts-moment-overlay-icon-warning {
  width: 30%;
}

#retry-blue-on-white {
  width: 30%;
  min-width: 40px;
  border: none;
  opacity: 1;
}

.ts-modal-content__uploading {
  padding: 2% 4% 2% 4%;
  height: 125px;
  display: flex;
  // justify-content: center;
}

.ts-modal-content__uploading-updating {
  justify-content: center;
}

.ts-modal-content__retry {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ts-modal-title__retry {
  background-color: $warning;
  color: white;
  width: 100%;
  height: 100%;
}

#ts-modal-retry__header-container {
  display: flex;
  align-items: center;
  width: 100%;
}

#ts-modal-retry__header {
  background-color: $warning;
  color: white;
  width: 100%;
  height: 100%;
  padding: 15px;
}

#ts-modal-retry__buttons {
  // grid-area: ts-modal-retry__header;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#ts-modal-retry__footer {
  // grid-area: ts-modal-retry__header;
  text-align: center;
  padding: 10px;
  font-family: $font-family-text;
}

.ts-modal-retry__button {
  // margin: 10px;
  height: 40px;
}

.ts-message-text__uploading {
  padding: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 18px;
}

// .ts-message-text__retry {
//   color: white
// }

.ts-message-text__ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

#ts-delete-failed-upload {
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 17%;
  aspect-ratio: 1;
  min-height: 30px;
  min-width: 30px;
  border-radius: 100%;
  border-width: 0;
  background-color: #f58a7d;
  color: white;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0;
  text-align: center;

  &:hover {
    background-color: #ff7a69;
  }
}

#sign-up-error {
  color: red;
  font-size: 14px;
}

.ts-sidemenu__text {
  font-family: $font-family-text;
}

.ts-sidemenu-signup__text {
 margin-bottom: 0.5rem;
}

.ts-sidemenu-giftcode__text {
 margin-bottom: 1.5rem;
}

#ts-sidemenu-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
}

.ts-span__clickable {
  color: #0067b9;

  &:hover {
    cursor: pointer;
  }
}

.ts-bllt {
  position: absolute;
  left: -14px;
  top: 4px;
  font-size: 8px;
}
.ts-bllt-failed {
  color: $warning;
}
.ts-bllt-missed {
  color: $primary;
}