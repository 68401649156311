@media (min-width: 1440px) {
  // === Carousel ===
  .lp-carousel__wrap-caption {
    left: 50%;
    margin-left: -720px;
  }
}

@media (max-width: 1440px) {
  .lp-how-to-block .lp-common__text {
    font-size: 1.2rem;
    line-height: 1.5rem;

    &.xs {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 1350px) {
  .lp-prices-block__image-text {
    left: -5%;
  }
}

// max-width 1200px
@media (max-width: breakpoint-max("lg")) {
  // === Layout ===
  .lp-container {
    padding-left: 100px;
    padding-right: 100px;
  }

  // === Carousel ===
  .lp-carousel__caption-title {
    font-size: 2rem;
    line-height: 3rem;
  }
  .lp-carousel__caption-subtitle {
    font-size: 1.3rem;
    line-height: 3rem;
  }

  // === Text ===
  .lp-common__title {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
  .lp-common__text {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .lp-common__text-small {
    font-size: 1.2rem;
    line-height: 2rem;

    &.xs {
      font-size: 0.8rem;
    }
  }

  // === Stories & Prices & Journal & Share blocks ===
  .lp-stories-block__image,
  .lp-prices-block__image-wrap,
  .lp-prices-block__image {
    height: 40rem;
  }
  .lp-journal-block__image,
  .lp-mobile-block__image {
    height: 30rem;
  }
  .lp-mobile-block__image {
    // right: 20%;
  }
  .lp-prices-block__image-text {
    left: 5%;
    bottom: 8%;
  }
}

@media (max-width: 1100px) {
  // === Prices block ===
  .lp-prices-block__image-text {
    left: -8%;
  }
}

// max-width 992px
@media (max-width: breakpoint-max("md")) {
  // === Layout ===
  .lp-container {
    padding: 0 50px;
  }
  .lp-separator {
    height: 4rem;
  }
  .lp-footer {
    padding: 3.5rem 0;
  }

  // === Text ===
  .lp-common__title {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .lp-common__subtitle {
    font-size: 1.3rem;
    line-height: 2.5rem;
  }
  .lp-common__text {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  // === Carousel ===
  // .lp-carousel__indicators li {
  //   width: 0.5rem;
  //   height: 0.5rem;
  //   border-radius: 0.25rem;
  // }
  // .lp-carousel__caption {
  //   max-width: 35%;
  // }
  // .lp-carousel__caption-title {
  //   font-size: 1.5rem;
  //   line-height: 2rem;
  // }
  // .lp-carousel__caption-subtitle,
  // .lp-carousel__caption-text {
  //   font-size: 1rem;
  //   line-height: 1.5rem;
  // }

  // === How it works block ===
  .lp-how-to-number {
    width: 40px;
    height: 40px;
    font-size: 25px;
    line-height: 40px;
  }

  // === Stories & Prices & Journal & Share blocks ===
  .lp-stories-block__image-wrap,
  .lp-journal-block__image-wrap {
    text-align: end;
  }
  .lp-prices-block__image-text {
    left: 10%;
  }
  .lp-stories-block__image,
  .lp-prices-block__image-wrap,
  .lp-prices-block__image,
  .lp-share-block__image-wrap,
  .lp-share-block__image {
    height: 30rem;
  }
  .lp-mobile-block__image-wrap,
  .lp-journal-block__image,
  .lp-mobile-block__image {
    height: 26rem;
  }

  // === Buttons ===
  .lp-btn {
    width: 13rem;
  }
}

@media (max-width: 860px) {
  // === Prices block ===
  .lp-prices-block__image-text {
    left: 0;
  }
}

// max-width 768px
@media (max-width: breakpoint-max("sm")) {
  // === Layout ===
  .lp-container {
    padding: 0 25px;
  }

  // === Carousel ===
  .lp-carousel__caption {
    max-width: 50%;
  }
  .lp-carousel__indicators li {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
  }
  // .lp-carousel__caption {
  //   max-width: 35%;
  // }
  .lp-carousel__caption-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .lp-carousel__caption-subtitle,
  .lp-carousel__caption-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  // === How it works block ===
  .lp-how-to-step {
    width: 80%;
  }
  .lp-how-to-steps {
    // justify-content: center !important;
    align-items: center;
  }

  // === Journal blocks ===
  .lp-mobile-block__image-wrap,
  .lp-journal-block__image,
  .lp-mobile-block__image {
    height: 18rem;
  }
  .lp-mobile-block__image {
    // right: 40%;
  }
  .lp-prices-block__image-text {
    left: 7%;
  }

  // === Footer ===
  .lp-social-list__item {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
  }
}

@media (max-width: 650px) {
  // === Carousel ===
  .lp-carousel__caption {
    max-width: 60%;
  }

  // === Prices block ===
  .lp-prices-block__image-text {
    left: -5%;
  }
}

// max-width 576px
@media (max-width: breakpoint-max("xs")) {
  // === Carousel ===
  .lp-carousel-inner {
    background-color: $dark;
  }
  .lp-carousel__wrap-caption {
    justify-content: center !important;
    position: relative;
    // padding-top: 2.5rem;
    background-color: white;
    color: $secondary;
    border: none;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .lp-carousel__caption {
    align-items: center !important;
    // position: relative;
    // padding-top: 2.5rem;
  }
  .lp-carousel__caption {
    max-width: 90%;
  }
  .lp-carousel__caption-title {
    // max-width: 15rem;
    text-align: center;
    margin-top: 1rem !important;
  }
  .lp-carousel-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .lp-carousel__indicators {
    top: 0;
    padding-top: 1rem;
  }

  // === Stories & Prices & Journal & Share blocks ===
  .lp-stories-block__image,
  .lp-prices-block__image-wrap,
  .lp-prices-block__image,
  .lp-share-block__image-wrap,
  .lp-share-block__image {
    height: 25rem;
  }
  .lp-prices-block__image-wrap {
    margin: 4rem 0 2rem;
  }
  .lp-prices-block__image {
    right: 20%;
  }
  .lp-prices-block__image-text {
    left: 20%;
    bottom: 5%;
    font-size: 0.8rem;
    line-height: 1.25rem;
  }

  // === Mobile block ===
  .lp-mobile-block__wrap,
  .lp-start-block__wrap {
    padding: 2.5rem 0;
  }

  // === Buttons ===
  .lp-btn {
    width: 100%;
  }
  .lp-btn__mobile {
    padding: 0.7rem 1rem;
  }
}

@media (max-width: 500px) {
  // === Prices block ===
  .lp-prices-block__image {
    right: 0;
  }
  .lp-prices-block__image-text {
    left: 30%;
  }


  // .lp-reset-password__content {
  //   height: 40%;
  // }
  // .lp-reset-password__content .m-5 {
  //   margin: 0 !important;
  // }
}
@media (max-width: 475px) {
  // === Prices block ===
  .lp-prices-block__image-text {
    left: 25%;
  }
}
@media (max-width: 450px) {
  // === Prices block ===
  .lp-prices-block__image-text {
    left: 20%;
  }
}
@media (max-width: 425px) {
  // === Prices block ===
  .lp-prices-block__image-text {
    left: 20%;
  }
}
@media (max-width: 400px) {
  // === Prices block ===
  .lp-prices-block__image-text {
    left: 10%;
  }
}
