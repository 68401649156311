@media (max-width: 1300px) {
  .ts-header__signin {
    // margin-left: 2rem;
  }
}

// max-width 1200px
@media (max-width: breakpoint-max("lg")) {
  // === Layout ===
  .ts-container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .ts-logo__img {
    width: 25px;
    height: 25px;
  }
  .ts-logo__name {
    width: 120px;
    height: 20px;
  }

  // === Navigation ===
  .ts-nav__link {
    padding: 0.5rem 0.5rem;
  }

  // === Alert ===
  .ts-alert__signup {
    padding: 0;
  }
}

@media (max-width: 1100px) {
  // === Layout ===
  .ts-logo__img {
    width: 20px;
    height: 20px;
  }
  .ts-logo__name {
    width: 96px;
    height: 15px;
  }
}

@media (max-width: 1024px) {
  // === App pop-up ===
  .ts-app-popup {
    display: block;
  }
  .ts-header__signin {
    margin-left: 1rem;
  }
}

// max-width 992px
@media (max-width: breakpoint-max("md")) {
  // === Layout ===
  .ts-header {
    min-height: 48px;
  }
  .ts-container {
    padding: 0 50px;
  }

  // === Logo ===
  .ts-logo__img {
    width: 20px;
    height: 20px;
  }
  .ts-logo__name {
    width: 96px;
    height: 15px;
  }

  // === Navigation ===
  .ts-nav__toggle {
    display: flex;
  }
  .ts-nav__wrap {
    display: none;
  }

  // === Alert ===
  .ts-alert__signup {
    padding: 0;
  }

  // === Avatar ===
  .ts-account {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  // === Sidemenu. Inner. ===
  .ts-skip__text {
    padding-right: 0rem;
  }

  // === Onboarding ===
  .ts-onboarding__messege-fixed .ts-btn {
    min-width: 10rem;
  }

  // === PhotosBox ===
  .ts-photos-box__header {
    padding: 2.5rem 0;
  }
  .ts-photos-box__image--border {
    border-width: 0.4rem;
  }

  // === Viewer ===
  .btn-arrow {
    height: 5rem;
    margin-top: -2.5rem;
    width: 2rem;
  }
  .arrow {
    width: 20px;
    height: 50px;
  }
  .btn-arrow__direction--left {
    left: 1rem;
  }

  .btn-arrow__direction--right {
    right: 1rem;
  }

  // === Modal-dialog. SignUp & Login & Submit ===
  .ts-modal-dialog__body {
    padding: 0 2.5rem 3.5rem;
  }

  // === Custom helper ===
  .ts-tooltip {
    padding: 2rem;
  }
  .ts-tooltip__header {
    & h4 {
      font-size: 1.2rem;
      line-height: 1.1rem;
    }

    // &:after {
    //   top: -2.8rem;
    //   left: -1rem;
    // }
  }
  .ts-tooltip__header-preferences:after {
    top: -2.8rem;
    left: -1rem;
  }
  .ts-tooltip__header-moments:after {
    top: -2.8rem;
    right: -1rem;
  }
  .ts-tooltip__header-account:after {
    top: -2.8rem;
    left: -1rem;
  }
}

// max-width 768px
@media (max-width: breakpoint-max("sm")) {
  // === Layout ===
  .ts-container {
    padding: 0 25px;
  }

  // === Alert ===
  .ts-alert {
    padding: 1rem 0;
  }
  .ts-alert__icon {
    display: none;
  }
  .ts-alert__signup {
    padding: 0;
  }

  // === Avatar ===
  .ts-account {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  // === Onboarding ===
  .ts-onboarding__messege-fixed span {
    text-align: center;
    width: 100%;
  }

  // === PhotosBox ===
  .ts-photos-box__image--border {
    border-width: 0.3rem;
  }

  // === Forms ===
  .ts-payment__form,
  .ts-sidemenu__form {
    width: 70%;
  }

  // === Buttons ===
  .ts-btn-upload,
  .ts-btn-submit {
    width: 100%;
  }
  .ts-social__btn {
    width: 9rem;
    height: 5rem;
    padding: 1rem 0;

    & .ts-social__btn__icon {
      transform: scale(1.5);
    }
  }

  .ts-next-month-button,
  .ts-past-month-button,
  .ts-year-btn {
    width: 500px;
  }

  // === Custom helper ===
  .ts-tooltip {
    padding: 1.5rem;
    width: 400px
  }
  .ts-tooltip__header {
    & h4 {
      padding-right: 2rem;
      font-size: 1rem;
    }

    // &:after {
    //   top: -2.3rem;
    //   left: -0.5rem;
    // }
  }
  .ts-tooltip__header-preferences:after {
    top: -2.3rem;
    left: -0.5rem;
  }
  .ts-tooltip__header-moments:after {
    top: -2.3rem;
    right: -0.5rem;
  }
  .ts-tooltip__header-account:after {
    top: -2.3rem;
    left: -0.5rem;
  }
  .ts-tooltip__body {
    font-size: 0.8rem;
  }
}

@media (max-width: 620px) {
  // === Buttons ===
  .ts-social__btn {
    width: 8rem;
    height: 4.5rem;
    padding: 0.75rem 0;

    & .ts-social__btn__icon {
      transform: scale(1.3);
    }
  }

  .ts-sidemenu__item-hint {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .ts-next-month-button,
  .ts-past-month-button,
  .ts-year-btn {
    width: 450px;
  }

  // === Tooltip ===
  .ts-tooltip {
    width: 350px;
  }
}

// max-width 576px
@media (max-width: breakpoint-max("xs")) {
  // === Avatar ===
  .ts-account {
    // position: relative;
    // padding: 1rem 2rem 1rem 0;
    // margin-left: 2rem;
    padding-left: 25px;
    padding-right: 25px;
  }
  .ts-avatar__img {
    width: 84px;
    height: 84px;
    border-radius: 42px;
  }
  .ts-account__name {
    // position: absolute;
    // top: 2rem;
    // left: 0;
    font-size: 1.5rem;
  }
  .ts-avatar-details__name {
    font-size: 1.5rem;
  }

  // === Alert ===
  .ts-alert__signup {
    width: 100%;
    padding: 0 25px;
  }

  // === Sidemenu ===
  .ts-modal-header {
    padding: 1.5rem 2rem 0rem 2rem;
  }
  .ts-sidemenu {
    padding: 0 2rem 2rem;
  }
  .ts-alert__sidemenu,
  .ts-inner-layout__header {
    padding: 0 2rem;
  }
  .ts-sidemenu,
  .ts-inner-layout__body {
    padding: 0 2rem 2rem;
  }
  .ts-sidemenu__title {
    padding: 1.5rem 0;
  }
  .ts-sidemenu__separate {
    height: 2rem;
  }
  .ts-sidemenu__item {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .ts-sidemenu__item-hint {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .ts-refer-friends {
    padding-right: 0;
  }

  // === PhotosBox ===
  .ts-photos-box__header {
    padding: 2rem 0;
  }

  // === Viewer. Setting of image ===
  .ts-setting {
    padding: 0.5rem;
  }

  .ts-btn__delete {
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    border-radius: 0.2rem;
  }

  // === Switch ===
  .ts-switch__photos-amount {
    width: 9.5rem;

    & > .ts-btn__switch {
      min-width: 3rem;
    }
  }

  // === Modal-dialog. SignUp & Login & Submit ===
  .ts-modal-dialog__body {
    padding: 0 1.5rem 2rem;
  }

  // === Forms ===
  .ts-payment__form,
  .ts-sidemenu__form {
    width: 100%;
  }
  .ts-message-help__text {
    padding-right: 0;
  }

  // === Buttons ===
  .ts-btn__sidemenu,
  .ts-btn-disabled {
    width: 100%;
  }

  .ts-tooltip__moments {
    top: 5rem !important;
    left: 1.5rem !important;
  }

  .ts-tooltip__account {
    left: -7rem !important;
  }

  .ts-tooltip__header-moments:after {
    left: 0.5rem;
  }

  .ts-tooltip__header-account:after {
    left: 6rem;
  }

  .ts-tooltip {
    width: 325px;
  }

  .ts-next-month-button,
  .ts-past-month-button,
  .ts-year-btn {
    width: 400px;
  }
  .ts-next-month-text,
  .ts-past-month-text {
    font-size: 16px;
  }
  
  // === Viewer ===
  .ts-setting__count-display {
    display: none !important;
  }
  .btn-arrow {
    height: 4rem;
    // border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
}

@media (max-height: 576px) {
  // === Viewer. Setting of image ===
  .ts-setting {
    padding: 0.5rem;
  }

  .ts-btn__delete {
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
  }

  // === Switch ===
  .ts-switch__photos-amount {
    width: 9.5rem;

    & > .ts-btn__switch {
      min-width: 3rem;
    }
  }
}
@media (max-height: 476px) {
  .ts-btn__delete {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
  }

  // === Switch ===
  .ts-switch__photos-amount {
    width: 7.5rem;

    & > .ts-btn__switch {
      min-width: 2.5rem;
      padding: 0.5rem;
    }
  }
}
@media (max-width: 460px) {
  // === Buttons ===
  .ts-social__btn {
    width: 6.5rem;
    height: 4rem;
    padding: 0.5rem 0;

    & .ts-social__btn__icon {
      transform: scale(1.1);
    }
  }

  .ts-message-text__retry {
    font-size: 16px;
  }

  .ts-google-account {
    flex-direction: column !important;
  }

  .ts-next-month-button,
  .ts-past-month-button,
  .ts-year-btn {
    width: 350px;
  }

  // === Tooltip ===
  .ts-tooltip__account {
    left: -9rem !important;
  }
  .ts-tooltip__header-account:after {
    left: 7rem;
  }
}

@media (max-width: 390px) {
  // === Buttons ===
  .ts-social__btn {
    width: 5rem;
    height: 3rem;
    padding: 0.3rem 0;

    & .ts-social__btn__icon {
      transform: scale(1);
    }
  }

  .ts-message-text__retry {
    font-size: 12px;
  }

  #ts-modal-retry__header {
    font-size: 17px;
  }

  .ts-next-month-button,
  .ts-past-month-button,
  .ts-year-btn {
    width: 300px;
  }

  // === Tooltip ===
  .ts-tooltip__account {
    left: -11rem !important;
  }
  .ts-tooltip__header-account:after {
    left: 9rem;
  }

}

@media (max-width: 370px) {
  .ts-sidemenu__item {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
  .ts-sidemenu__item-hint {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
}

@media (min-width: 768px) {
  .ct-toast {
    width: 30rem !important;
    margin-right: 6rem !important;
    justify-content: start !important;

    & svg {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
  .ct-text {
    font-size: 1.2rem !important;
  }
}
