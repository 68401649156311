// === Layout ===
.lp-main {
  min-width: 320px;
  background-color: $light;
  overflow: hidden;
}
.lp-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
}
.lp-separator {
  height: 7.5rem;
}
.lp-footer {
  min-width: 320px;
  padding: 5rem 0 3.5rem;
  background-color: $secondary;
  color: $light;
}
.lp-footer__rp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%
}
.lp-footer__link {
  color: white;
  text-decoration: underline;

  &:hover {
    color: $primary
  } 
}
.lp-footer__pp {
  margin-right: 10px
}
.lp-footer__tos {
  margin-left: 10px
}

// === Text ===
.lp-common__title {
  font-size: 2rem;
  line-height: 3.5rem;
}
.lp-common__subtitle {
  font-size: 1.4rem;
  line-height: 3rem;
}
.lp-common__center {
  text-align: center;
}
.lp-common__text {
  font-family: $font-family-text;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.lp-common__text-small {
  font-size: 1.2rem;
  line-height: 1.5rem;

  &.xs {
    font-size: 0.8rem;
  }
}
.lp-common__text-tight {
  line-height: 1.8rem;
}

// === Carousel ===
.lp-carousel__indicators li {
  width: 1rem;
  height: 1rem;
  border: none;
  border-radius: 0.5rem;
}
.lp-carousel__wrap-caption {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  color: $light;
  z-index: 10;
}
.lp-carousel__caption {
  max-width: 35rem;
}
.lp-carousel__caption-title {
  font-size: 2.5rem;
  line-height: 4rem;
  // text-align: center;
}
.lp-carousel__caption-subtitle {
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: center;
}
.lp-carousel__caption-text {
  margin: 0;
  font-family: $font-family-text;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}
.lp-carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}

// === How it works block ===
.lp-how-to-step {
  width: 23vw;
}
.lp-how-to-number {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: $info;
  font-size: 30px;
  line-height: 50px;
  align-self: center;
}

// === Prices & Share block ===
.lp-prices-block__image-wrap,
.lp-share-block__image-wrap {
  position: relative;
  height: 52rem;
}
.lp-share-block__image-wrap {
  height: 40rem;
}
.lp-mobile-block__image-wrap {
  position: relative;
  height: 30rem;
}
.lp-prices-block__image-text {
  position: absolute;
  bottom: 12%;
  left: 0;
  font-family: $font-family-base;
}
.lp-prices-block__image,
.lp-share-block__image {
  position: absolute;
  top: 0;
  right: 0;
}
.lp-share-block__image {
  right: 10%;
}
.lp-mobile-block__image {
  position: absolute;
  top: 0;
  right: 10%;
}
.lp-how-to-block .lp-common__text {
  text-align: center;
}

.lp-flexibility-block-image {
  height: 800px;
}

// === Video block ===
.lp-video-player__wrap {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  overflow: hidden;
}
.lp-video-player {
  position: absolute;
  top: 0;
  left: 0;
}

// === Mobile block ===
.lp-mobile-block__wrap,
.lp-start-block__wrap {
  padding: 5rem 0;
  background-color: $info;
  text-align: center;
}
.lp-pricing-block__wrap {
  padding: 2rem 0;
  background-color: $info;
  text-align: center;
}

// === Footer ===
.lp-social-list {
  list-style: none;
}
.lp-social-list__item a {
  color: $light;
}
.lp-social-list__item {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}
.lp-social-list__item--instagram {
  background-color: $instagram;
}
.lp-social-list__item--pinterest {
  background-color: $pinterest;
}
.lp-footer__text {
  font-family: $font-family-base;
}
.lp-social-list__item--instagram:hover a {
  color: $instagram;
}
.lp-social-list__item--pinterest:hover a {
  color: $pinterest;
}
.lp-social-list__item--instagram:hover,
.lp-social-list__item--pinterest:hover {
  background-color: $light;
}

// === Buttons ===
.lp-btn {
  padding: 0.7rem 1.5rem;
  width: 16rem;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}
.lp-btn__mobile {
  padding: 0.7rem 2rem;
  &:hover {
    background-color: $dark_secondary;
    border-color: $dark_secondary;
  }
  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

.lp-reset-password {
  border-top: solid 1px $secondary;
  background-color: white;
  flex: 1;
  max-width: 100% !important;
  // height: 90vh;
}

// .lp-reset-password__content {
//   height: 400px;
// }

#lp-reset-password__container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // justify-content: space-between;
}

.lp-gdpr-banner {
  font-family: $font-family-base;
}